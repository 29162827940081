export const de = {
  generic: {
    or: 'oder'
  },
  navbar: {
    pricing: 'Preise',
    features: 'Features',
    user: {
      profile: {
        title: 'Profil',
        edit: 'Profil bearbeiten',
        faq: 'FAQ'
      },
      manageSubscription: 'Abonnement verwalten'
    }
  },
  homepage: {
    heading_1: 'Erstellen Sie in wenigen Minuten eine',
    heading_2: 'persönliche',
    heading_3: 'und',
    heading_4: 'individuelle Erinnerung',
    description: 'Erwecken Sie Ihre Erinnerungen zum Leben und schaffen Sie bleibende Spuren für die Nachwelt',
    features: {
      title: 'Unsere Funktionen',
      description: 'Wir arbeiten kontinuierlich daran, unsere Funktionen zu erweitern, um sicherzustellen, dass Ihr Erlebnis auf unserer Plattform so angenehm wie möglich ist.',
      feature_list: {
        reminderPages: {
          title: 'Individuelle Erinnerungsseiten', // Jede seite soll einizigartig sein
          description: 'Erstellen Sie einzigartige Seiten, die persönliche Erlebnisse und Meilensteine auf besondere Weise festhalten.'
        },
        qrCodeAccess: {
          title: 'QR-Code-Zugänglichkeit', // Seite ist einfach über einen QR Code erreichbar
          description: 'Jede Seite ist bequem über einen QR-Code zugänglich, sodass Erinnerungen jederzeit leicht abrufbar sind.'
        },
        personalPage: {
          title: 'Persönliche Plattform', // Die Seite soll die Persönlichkeit der Person wiederspiegeln können
          description: 'Gestalten Sie ihre individuelle Plattform, die die Persönlichkeit und Individualität der geehrten Person widerspiegelt.'
        },
        media: {
          title: 'Bild und Beschreibung', // Man kann neben der Biographie auch Bilder hinterlegen (Es fehlt die Lebenslinie)
          description: 'Ergänzen Sie die Biographie mit Bildern und persönlichen Beschreibungen, um das Andenken noch lebendiger zu gestalten.'
        },
        lifeline: {
          title: 'Chronologische Lebenslinie', // Eine Zeitleiste auf der man wichtige Events nochmal hervorheben kann
          description: 'Hervorheben Sie bedeutende Ereignisse auf einer Zeitleiste, die das Leben der Person chronologisch darstellt und unvergessliche Momente anschaulich nacherleben lässt.'
        },
        protect: {
          title: 'Schutz', // Die Seite ist sicher
          description: 'Schützen Sie Ihre Erinnerungsseite durch ein Passwort. Somit haben nur ausgewählte Personen Zugriff auf ihre Erinnerungen.'
        },
        availability: {
          title: 'Dauerhafte Verfügbarkeit', // Immer online
          description: 'Ihre Erinnerungsseiten bleiben für kommende Generationen online verfügbar und zugänglich.'
        },
        availableEverywhere: {
          title: 'Überall Abrufbar', // Von überall auf der Welt aufrufbar.
          description: 'Greifen Sie weltweit auf Ihre Erinnerungen zu – jederzeit und von jedem Ort der Welt.'
        }
      }
    },
    pricing: {
      title: 'Preise',
      description: 'Egal, ob Sie unseren Service nur ausprobieren möchten oder mehr benötigen, wir stehen Ihnen in beiden Fällen zur Verfügung.',
      free: {
        title: 'Free',
        description: 'Für jedermann',
        quote: 'Um die Anwendung zu testen und vertrauen zu schaffen',
        features: {
          pageLimit: {
            text: 'Eine Seite',
            footnote: 'Die maximale Anzahl an Seiten die Sie erstellen können.'
          },
          dataLimit: {
            text: '2MB Dateigrößen Limit',
            footnote: 'Die maximale Größe von Bildern die hochgeladen werden können.'
          },
          design: {
            text: 'Benutzerfreundliche Oberfläche',
            footnote: 'Lorem Ipsum' // Todo: Muss noch ergänzt werden
          },
          prioritySupport: {
            text: 'Priorisierter Kundenservice',
            negative: true
          }
        }
      },
      pro: {
        title: 'Pro',
        description: 'For larger projects with higher needs', // Todo: Muss noch angepasst werden
        quote: 'Für Personen die viel erlebt haben und viel zu erzählen haben',
        comingSoon: 'Bald erhältlich',
        features: {
          pageLimit: {
            text: '5 Seiten',
            footnote: 'Die maximale Anzahl an Seiten die Sie erstellen können.'
          },
          dataLimit: {
            text: '5MB Dateigrößen Limit',
            footnote: 'Die maximale Größe von Bildern die hochgeladen werden können.'
          },
          lifeline: {
            text: 'Lebenslinie',
            footnote: 'Lorem Ipsum' // Todo: Muss noch ergänzt werden
          },
          design: {
            text: 'Benutzerfreundliche Oberfläche'
          },
          prioritySupport: {
            text: 'Priorisierter Kundenservice'
          }
        }
      },
      perMonth: 'Pro Monat'
    },
    faq: {
      title: 'FAQ',
      description: 'Hier werden häufig gestellte Fragen beantwortet.',
      reasonToUse: {
        title: 'Warum sollten Sie uns verwenden?',
        description: 'Erstellen Sie ganz einfach individuelle Erinnerungsseiten, um Ihre persönlichen Erlebnisse und Errungenschaften ' +
          'für die Nachwelt festzuhalten – alles bequem über einen QR-Code zugänglich.'
      },
      reasonToTrust: {
        title: 'Warum sollten Sie uns vertrauen?',
        description: 'Unsere Anwendung gewährleistet höchste Sicherheit und Datenschutz, wird aktiv weiterentwickelt und ' +
          'bietet Ihnen die Zuverlässigkeit, die Sie für die Aufbewahrung Ihrer wertvollsten Erinnerungen benötigen.'
      },
      reasonToChoose: {
        title: 'Warum sollten Sie genau uns auswählen?',
        description: 'Mit einer benutzerfreundlichen Oberfläche, innovativen Funktionen zur Personalisierung Ihrer Erinnerungsseiten ' +
          'und einem engagierten Support-Team sind wir die beste Wahl für Ihre Erinnerungen, die nicht in Vergessenheit geraten sollen.'
      },
      questionsRemain: {
        title: 'Sie haben noch fragen?',
        description: 'Sollten Sie noch Fragen haben, zögern Sie bitte nicht, uns zu kontaktieren.'
      }
    }
  },
  dashboard: {
    title: 'Dashboard',
    posts: 'Meine Beiträge',
    add: 'Hinzufügen',
    memory: {
      created_at: 'Erstellt am ',
      updated_at: 'Aktualisiert am '
    }
  },
  profile: {
    generalInformation: {
      title: 'Profil Informationen',
      description: 'Aktualisieren Sie die Profilinformationen und die E-Mail-Adresse Ihres Kontos.',
      firstname: 'Vorname',
      lastname: 'Nachname',
      email: {
        title: 'E-Mail',
        notVerified: 'Ihre E-Mail-Adresse ist nicht verifiziert.',
        resendButton: 'Klicken Sie hier, um die Bestätigungs-E-Mail erneut zu senden.',
        linkSent: 'Es wurde ein neuer Verifizierungslink an Ihre E-Mail-Adresse gesendet.'
      }
    },
    changePassword: {
      title: 'Passwort aktualisieren',
      description: 'Stellen Sie sicher, dass Ihr Konto ein langes, zufälliges Passwort verwendet, um sicher zu sein.',
      currentPassword: 'Aktuelles Passwort',
      newPassword: 'Neues Passwort',
      confirmPassword: 'Bestätigen Sie Ihr Passwort'
    },
    oauth: {
      title: 'Social-Media Verbindungen',
      missingInfos: 'Es wurden nicht alle Informationen aus Ihrem Profil übernommen. Bitte ergänzen Sie die leeren Felder.'
    },
    deleteAccount: {
      title: 'Konto löschen',
      description: 'Sobald Ihr Konto gelöscht ist, werden alle Ressourcen und' +
        ' Daten dauerhaft gelöscht. Bevor Sie Löschen Ihres Kontos' +
        ' laden Sie bitte alle Daten und Informationen herunter, die' +
        ' Sie aufbewahren möchten.',
      confirmation: {
        title: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?',
        description: 'Sobald Ihr Konto gelöscht ist, werden alle Daten dauerhaft gelöscht.' +
          ' Bitte geben Sie ihre E-Mail ein, um zu bestätigen, dass Sie Ihr Konto dauerhaft löschen möchten.'
      }
    }
  },
  buttons: {
    login: 'Anmelden',
    logout: 'Abmelden',
    startNow: 'Jetzt starten',
    upgradeNow: 'Jetzt upgraden',
    register: 'Registrieren',
    dashboard: 'Dashboard',
    delete: 'Löschen',
    deleteAccount: 'Konto löschen',
    abort: 'Abbrechen',
    save: 'Speichern',
    back: 'Zurück',
    retry: 'Nochmal versuchen',
    resetPassword: 'Passwort zurücksetzen',
    add: 'Hinzufügen',
    sendEmail: 'Email schreiben!'
  },
  auth: {
    email: 'E-Mail',
    password: 'Passwort',
    repeatPassword: 'Passwort wiederholen',
    rememberMe: 'Anmeldung merken',
    forgotPassword: {
      title: 'Passwort vergessen',
      description: 'Sie haben Ihr Passwort vergessen? Kein Problem! ' +
        'Teilen Sie uns einfach Ihre E-Mail-Adresse mit und wir senden Ihnen einen zurücksetzen, ' +
        'über den Sie ein neues Passwort wählen können.'
    },
    noUser: 'Noch keinen Benutzer?',
    alreadyHasUser: 'Sie besitzen schon einen Benutzer?',
    verified: 'Benutzer erfolgreich verifiziert',
    loginForm: {
      login: 'Anmelden'
    }
  },
  register: {
    title: 'Registrieren',
    firstname: 'Vorname',
    lastname: 'Nachname',
    confirmPassword: 'Password bestätigen',
    alreadyRegistered: 'Schon registriert?'
  },
  verifyEmail: {
    description: 'Vielen Dank für die Anmeldung! Bevor Sie beginnen, könnten Sie Ihre ' +
      'E-Mail-Adresse verifizieren, indem Sie auf den Link klicken, den wir ' +
      'Ihnen soeben per E-Mail geschickt haben? Wenn Sie die E-Mail nicht ' +
      'erhalten haben, schicken wir Ihnen gerne eine neue zu.',
    resendEmail: 'Verifizierungsmail erneut senden',
    linkSent: 'Es wurde ein neuer Verifizierungslink an die  E-Mail-Adresse gesendet, ' +
      'die Sie bei der Registrierung angegeben haben.'
  },
  form: {
    title: {
      label: 'Titel',
      placeholder: 'Ihre Überschrift'
    },
    text: {
      label: 'Inhalt',
      placeholder: 'Was möchten Sie hinterlegen?'
    },
    date: {
      label: 'Datum',
      placeholder: 'Wählen Sie ein Datum aus'
    }
  },
  memory: {
    biography: 'Biografie',
    events: {
      title: 'Lebenslinie',
      description: 'Hier werden Nennens würdige Augenblicke genannt',
      form: {
        add: {
          title: 'Ereignis hinzufügen',
          description: 'Fügen Sie ein Ereignis hinzu'
        },
        edit: {
          title: 'Ereignis bearbeiten',
          description: 'Bearbeiten Sie ihr ausgewähltes Ereignis'
        },
        delete: {
          title: 'Ereignis wirklich löschen?',
          description: 'Möchten Sie wirklich dieses Ereignis löschen? Dieser Schritt ist nicht mehr umkehrbar.'
        },
        remainingChars: '{{ chars }} Zeichen verbleiben'
      }
    },
    delete: {
      title: 'Erinnerung wirklich löschen?',
      description: 'Möchten Sie wirklich diese Erinnerung löschen? Dieser Schritt ist nicht mehr umkehrbar.'
    },
    protect: {
      title: 'Erinnerung schützen',
      description: 'Schützen Sie ihre Erinnerung mit einem Passwort.' +
        ' Nur Personen die dieses Passwort besitzen können auf diese Seite zugreifen.',
      cancel: 'Schutz aufheben',
      activate: 'Schutz aktivieren'
    },
    downloadError: 'Bei dem Versuch den QR Code herunterzuladen kam es zu einem Problem',
    emptyTitle: 'Ziemlich leer hier',
    emptyDescription: 'Erstellen Sie ihren ersten Beitrag.',
    tooltip: 'Momentan ist nur ein Beitrag pro Benutzer erlaubt.'
  },
  verifyPassword: {
    title: 'Passwort eingeben',
    description: 'Diese Erinnerung wurde mit einem Passwort geschützt. Bitte geben Sie das korrekte Passwort' +
      ' ein, um auf diese Erinnerung zugreifen zu können.',
    authenticate: 'Authentifizieren'
  },
  apiResponse: {
    success: {
      text: 'Ihre Änderungen wurden erfolgreich übernommen.',
      add: 'Ihre Eingaben wurden erfolgreich gespeichert.'
    },
    error: {
      title: 'Uh oh! Etwas ist schief gelaufen.',
      media: {
        maxReached: 'Die maximale Anzahl ({{ amount }}) an erlaubten Medien wurde erreicht.' // TODO: Überarbeiten
      }
    },
    info: {
      nothingChanged: {
        title: "Es hat sich nichts geändert."
      }
    }
  },
  empty: {
    title: 'Ziemlich leer hier',
    description: 'Erstellen Sie ihren ersten Beitrag.',
    descriptionNotOwner: 'Bisher wurden noch keine Ereignisse hinzugefügt.'
  },
  footer: {
    cookiePolicy: 'Cookie Policy',
    conditions: 'AGB',
    dataProtection: 'Datenschutz',
    imprint: 'Impressum'
  }
};
