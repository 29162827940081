import * as Sentry from "@sentry/react";
import {router} from "@inertiajs/react";

export function initSentry() {
    const client = Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        integrations: [
            Sentry.browserTracingIntegration({
                instrumentNavigation: false,
                instrumentPageLoad: false,
            }),
            Sentry.replayIntegration(),
            Sentry.feedbackIntegration({
                colorScheme: "System",
                isEmailRequired: true
            }),
        ],

        // Performance Monitoring
        tracesSampleRate: 0.2, //  Capture 20% of the transactions
        tracePropagationTargets: [/^https:\/\/reminara\.de/, /^https:\/\/reminara\.com/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });

    // We start the pageload span as early as possible!
    let name = route().current()!;
    let pageLoadSpan = Sentry.startBrowserTracingPageLoadSpan(client!, {
        name: name,
        attributes: {
            [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "url",
        },
    });

    router.on("navigate", () => {
        if (pageLoadSpan) {
            pageLoadSpan.updateName(route().current()!);
            pageLoadSpan.setAttribute(
                Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE,
                "route",
            );
            pageLoadSpan = undefined;
        } else {
            Sentry.startBrowserTracingNavigationSpan(client!, {
                op: "navigation",
                name: route().current()!, // or what the name of the span should be
                attributes: {
                    [Sentry.SEMANTIC_ATTRIBUTE_SENTRY_SOURCE]: "route",
                },
            });
        }
    });
}
