import './bootstrap';
import '../css/app.css';
import './lib/i18n.tsx';

import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {initSentry} from "@/lib/sentryConfig";

const appName = import.meta.env.VITE_APP_NAME || 'reminara';

createInertiaApp({
    title: (title) => appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
    setup({el, App, props}) {
        initSentry()

        const root = createRoot(el);
        root.render(<App {...props} />);
    },
    progress: {
        color: '#4B5563'
    },
});
